<template>
  <div class="EmpresasLogin">
    <section class="technical-drawing__section empresas-login">
      <header class="empresas-login__header">
        <div class="empresas-login__inner container">
          <div class="empresas-login__header-content">
            <h2 class="empresas-login__heading heading">
              Inicia sesión como empresa
            </h2>
            <v-card flat :loading="cargando" :disabled="cargando">
              <p class="review__text mb24">
                En nuestro portal de atención a empresas podrás solicitar tus
                facturas, servicios de ambulancia y más a través de este portal.
              </p>
              <p class="mt24">
                Para iniciar sesión deberás hacer uso de tu correo electrónico y
                R.F.C.
              </p>
              <v-text-field
                v-model="usuario"
                label="Correo electrónico"
                filled
                :disabled="cargando"
                prepend-inner-icon="email"
                @keyup.native.enter="_doLogin"
              />
              <v-text-field
                v-model="password"
                label="R.F.C."
                filled
                :disabled="cargando"
                prepend-inner-icon="vpn_key"
                v-mask="'NNNNNNNNNNNNN'"
                @keyup.native.enter="_doLogin"
              />
              <v-btn
                block
                class="primary"
                large
                depressed
                @click="_doLogin"
                v-text="'Iniciar sesión'"
              />
              <v-btn
                block
                class="mt16"
                to="/empresas/registro"
                text
                v-text="'Registrarme'"
              />
            </v-card>
          </div>
        </div>
      </header>
    </section>
  </div>
</template>
<script>
export default {
  name: "Login",
  data() {
    return {
      usuario: "",
      password: "",
      cargando: false,
    };
  },
  methods: {
    async _doLogin() {
      this.cargando = true;
      try {
        const { status, body } = await this.$http.post("login", {
          a: this.usuario,
          b: this.password,
        });
        this.cargando = false;
        if (status === 200 && "token" in body) {
          this.$session.start();
          delete this.$headers.headers.Token;
          this.$headers.headers.Token = body.token;
          this.$session.set("nombre", body.razon_social_don);
          this.$session.set("token", body.token);
          this.$session.set("tipo", "donador");
          if ("proveedor" in body)
            this.$session.set("proveedor", body.proveedor);

          this.$emit("msg", "Hola " + body.razon_social_don);
          this.$router.push("/empresas");
          this.$EventBus.$emit("menu-empresas-change", true);
        } else {
          this.$emit("msg", body.error);
        }
        this.cargando = false;
      } catch (err) {
        this.$emit("httpError", err);
        this.cargando = false;
      }
    },
  },
  created() {
    this.$session.destroy();
    delete this.$headers.headers.Token;
    delete this.$headers.headers.token;
    this.$EventBus.$emit("menu-empresas-change", true);
    this.$emit("visible", false);
    this.$emit("classes", "");
    if (this.$route.params.email) {
      this.usuario = this.$route.params.email;
      this.$router.push("/empresas/login");
    }
  },
};
</script>
